import { getOrCreateDataObject } from "o365.vue.ts";
const data: Array<InputConfig> = [];

export const dsInputEditors = getOrCreateDataObject({
    id: 'ds_o365_inputEditorsConfig',
    viewName: "sviw_Database_AvailableInputEditors",
    maxRecords: 1000,

    fields: [{ name: "PrimKey" }, { name: "ID", type: "number" }, { name: "Name" }, { name: "Config", type: "json" }, { name: "Label" }]
});

if(dsInputEditors.fields['Config'])
dsInputEditors.fields['Config'].jsonAlias = "ConfigJson";

dsInputEditors.load();

export async function getConfig(pEditor: any) {
    if (dsInputEditors.state.loadingPromise){
        await dsInputEditors.state.loadingPromise;
    }
    if (!data.length) {
        dsInputEditors.data.forEach(item => {
            data.push(new InputConfig(item));
        })
    }

    var vConfig = data.find(x => x.id == pEditor);
    if (!vConfig) {
        console.warn(`${pEditor} is not configured`);
        return null;
    }

    return vConfig;
}

export class InputConfig {
    id: string;
    columns: Array<any> = [];
    config: any;
    private _contextEnabled:boolean = false;

    get display() {
        return this.config.DisplayMember;
    }

    get dataObject() {
        if (!this.viewName) return null;
        try {
            if(!this._contextEnabled && this.config.RestrictToContext){
                this._contextEnabled = true;
                return  getOrCreateDataObject({
                    id: 'ds_o365_inputEditorsLkp_' + this.viewName,
                    appId: 'non-app',
                    viewName: this.viewName,
                    maxRecords: 25,
                    fields: this.columns
                }).enableContextFilter({ 'idPathField': "OrgUnitIdPath" });
            }
            return getOrCreateDataObject({
                id: 'ds_o365_inputEditorsLkp_' + this.viewName,
                appId: 'non-app',
                viewName: this.viewName,
                maxRecords: 25,
                fields: this.columns
            });
        } catch {
            return null;
        }
    }

    get type() {
        return this.config.Type;
    }

    get valueMember() {
        return this.config.ValueMember;
    }

    get viewName() {
        return this.config.ViewName;
    }

    constructor(pItem: any) {
        this.id = (pItem.PropertyName ?? pItem.Name).toLowerCase();
        if (pItem.ConfigJson) {
            this.config = pItem.ConfigJson;
        } else {
            try {
                this.config = JSON.parse(pItem.Config)
            } catch {
                console.warn("Failed to parse JSON", pItem);
            }
        }

        if (this.config.Columns) {
            this.config.Columns.split(",").forEach((col: any) => {
                this.columns.push(new Column(col));
            })
        }

        if (this.config.Type == "OrgUnit") {
            this.config.ValueMember = "ID";
            this.config.DisplayMember = "Name";
        }

        if (this.config.Type == "Object") {
            this.config.ValueMember = "ID";
            this.config.DisplayMember = "Name";
        }

        if (this.config.SortOrder) {
            const vTmp = this.config.SortOrder.split(":");
            const vCol = this.columns.find(x => x.name == vTmp[0]);
            if (vCol) {
                vCol.sortOrder = 1;
                vCol.sortDirection = vTmp[1];
            }
        }

       /* if (this.viewName && this.config.RestrictToContext) {
            this.dataObject?.enableContextFilter({ 'idPathField': "OrgUnitIdPath" });
        }*/
    }
}

class Column {
    name: string;
    width: string;
    type: string;
    constructor(pField: any) {
        const vTmp = pField.split(":");
        this.name = vTmp[0];
        this.width = vTmp[1];
        this.type = vTmp[2];
    }
}